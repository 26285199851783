// -------------------------- 公共模块 --------------------------
/**
 * 官网头部tabs
 * @see src/components/Header.vue
 */
export const headerTabs = [
  { id: 1, key: 'Home', name: '首页' },
  // { id: 2, key: 'Solution', name: '解决方案' },
  { id: 3, key: 'AppCenter', name: '设备中心' },
  { id: 4, key: 'OpenPlatform', name: '开放平台' },
]
/**
 * 官网头部btns
 * @see src/components/Header.vue
 */
export const headerBtns = {
  ai: '我的AI助理',
  saas: '我的运营平台',
  reg: '免费注册',
}
/**
 * 官网底部相关数据
 * @see src/components/Footer.vue
 */
export const footer = {
  links: [
    {
      id: 1,
      label: '企业微信',
      value: 'MetacareAGI',
      icon: require('@/assets/image/footer-wechat.png'),
    },
    {
      id: 2,
      label: '邮箱',
      value: 'customer@hahacloud.com',
      icon: require('@/assets/image/footer-email.png'),
    },
  ],
  serviceCases: [
    {
      id: 1,
      label: '产品服务',
      value: ['政府养老', '社区养老', '机构养老', '医养结合'],
    },
    // {
    //   id: 2,
    //   label: '项目案例',
    //   value: ['栖霞虚拟养老院', '浦口居家养老', '悦心市场化老人', '福寿康养老服务'],
    // },
  ],
  copyright: {
    company: 'Copyright © 2023 南京哈哈云信息科技有限公司 保留所有权利',
    record: {
      name: '苏ICP备2021031834号',
      link: 'https://beian.miit.gov.cn',
    },
  },
}

// -------------------------- 首页 --------------------------
/**
 * 首页-banner
 * @see src/views/Home/index.vue
 */
export const homeBanner = {
  title: 'Metacare',
  btnText: '即刻体验',
  subTitle: 'AIGC + 低代码的个性化人工智能助理',
  desc: '为个人和企业服务，通过AI助理来管理老人的健康。 应用在居家养老，健康管理，医养结合等服务领域。',
  icon: require('@/assets/image/home/banner_icon.png'),
}
/**
 * 首页-模块标题
 * @see src/views/Home/index.vue
 */
export const homeCardTitles = {
  intro: {
    title: '产品效果',
    letter: 'Product Show',
  },
  workflow: {
    title: '自定义工作流',
    letter: 'Workflow',
  },
  ai: {
    title: '自定义AI助理',
    letter: 'AI Agent',
  },
  intelligence: {
    title: '居家人工智能设备',
    letter: 'Intelligent Hardware',
  },
  moreDevices: {
    title: '已为您集成100+智能硬件设备',
    subTitle: '我们同时支持集成您的自有产品',
    letter: 'Over a Hundred',
  },
}
/**
 * 首页-产品介绍
 * @see src/views/Home/index.vue
 */
export const homeIntroduces = [
  {
    id: 2,
    title: 'C端 面向服务对象的AI产品',
    desc: '直接使用的智能设备，和通过小程序或屏幕对话的机器人',
    icon: require('@/assets/image/home/product_c.png'),
  },
  {
    id: 1,
    title: 'B端 定义个性化工作流程',
    desc: '通过AIGC+低代码SaaS平台，为不同角色定义个性化的工作流程',
    icon: require('@/assets/image/home/product_b.png'),
  },
]

/**
 * 首页-自定义工作流
 * @see src/views/Home/index.vue
 */
export const homeWorkflows = [
  {
    id: 1,
    title: '在线表单',
    desc: ['丰富组件配置', '灵活定制填写表单'],
    icon: require('@/assets/image/home/icon_workflow_form.png'),
    thumbnail: require('@/assets/image/home/icon_workflow_form_b.png'),
  },
  {
    id: 2,
    title: '流程引擎',
    desc: ['自由配置工作流程', '节点类型多样'],
    icon: require('@/assets/image/home/icon_workflow_engine.png'),
    thumbnail: require('@/assets/image/home/icon_workflow_engine_b.png'),
  },
  {
    id: 3,
    title: '数据报表',
    desc: ['多维度可视化分析', '简单拖拽生成'],
    icon: require('@/assets/image/home/icon_workflow_data.png'),
    thumbnail: require('@/assets/image/home/icon_workflow_data_b.png'),
  },
]
export const aiAgents = {
  features: [
    { id: 1, icon: require('@/assets/image/home/ai_set.png'), label: ['自定义角色设定提示词'] },
    { id: 2, icon: require('@/assets/image/home/ai_hello.png'), label: ['自由配置开场白及提问设定'] },
    {
      id: 3,
      icon: require('@/assets/image/home/ai_file.png'),
      label: ['支持导入您的自有知识库', '引导机器人回答固定内容'],
    },
  ],
  agents: [require('@/assets/image/home/ai_agent_1.png'), require('@/assets/image/home/ai_agent_2.png')],
  thumbnail: require('@/assets/image/home/ai_thumbnail.png'),
}
export const intelligences = [
  {
    id: 1,
    title: '智能护理',
    label: ['通过行为识别等技术', '实现老年人的健康监测及安全防护'],
    thumbnail: require('@/assets/image/home/intl_home_care.png'),
  },
  {
    id: 2,
    title: '智能健康管理',
    label: ['实时跟踪老年人的身体各项指标', '予以主动提示及健康建议'],
    thumbnail: require('@/assets/image/home/intl_home_health.png'),
  },
]
/**
 * 首页-100+集成硬件设备
 * @see src/views/Home/index.vue
 */
export const moreDevices = [
  { icon: require('@/assets/image/home/app_x8.png'), label: '陪伴机器人', brand: '小度', hot: true },
  { icon: require('@/assets/image/home/app_tmall.png'), label: 'CC10', brand: '天猫精灵', hot: true },
  { icon: require('@/assets/image/home/app_hw_watch.png'), label: '健康手表B9', brand: '华为' },
  { icon: require('@/assets/image/home/app_swcan.png'), label: '动态心电仪', brand: '数维康', hot: true },
  { icon: require('@/assets/image/home/app_qlz.png'), label: '中医诊脉仪', brand: '俏郎中', hot: true },
  { icon: require('@/assets/image/home/app_tencent_card.png'), label: '银龄守护卡', brand: '数维康' },
  { icon: require('@/assets/image/home/app_ql_radar.png'), label: '防跌倒雷达', brand: '清澜', hot: true },
  { icon: require('@/assets/image/home/app_ma_sos.png'), label: 'SOS', brand: '美安' },
  { icon: require('@/assets/image/home/app_ma_menci.png'), label: '门磁', brand: '美安' },
  { icon: require('@/assets/image/home/app_ma_ranqi.png'), label: '燃气报警', brand: '美安' },
  { icon: require('@/assets/image/home/app_ma_yanwu.png'), label: '烟雾报警', brand: '美安' },
  { icon: require('@/assets/image/home/app_ma_hongwai.png'), label: '红外', brand: '美安' },
  { icon: require('@/assets/image/home/app_ma_shuijin.png'), label: '水浸报警', brand: '美安' },
  { icon: require('@/assets/image/home/app_hk.png'), label: '摄像头', brand: '海康' },
  { icon: require('@/assets/image/home/app_sn_xueya.png'), label: '血压仪', brand: '三诺' },
  { icon: require('@/assets/image/home/app_sn_xuetang.png'), label: '血糖仪', brand: '三诺' },
  { icon: require('@/assets/image/home/app_sn_niaosuan.png'), label: '尿酸仪', brand: '三诺' },
]

// -------------------------- 应用中心 --------------------------
/**
 * 应用中心页面左侧菜单列表
 * @see src/views/AppCenter/index.vue
 */
export const appCenterNavs = [
  { id: 1, name: '陪伴机器人', value: 'COMPANION_ROBOT' },
  { id: 2, name: '健康监测', value: 'HEALTH_MONITORING' },
  { id: 3, name: '心电监测', value: 'ECG_MONITORING' },
  { id: 4, name: '可穿戴设备', value: 'WEARABLE_DEVICE' },
  { id: 5, name: '居家安防', value: 'HOME_SECURITY' },
  { id: 6, name: '毫米波雷达', value: 'MILLIMETER_WAVE_RADAR' },
  { id: 7, name: '实时监控', value: 'REALTIME_MONITORING' },
  { id: 8, name: '社交应用', value: 'SOCIAL_APPLICATION' },
  { id: 9, name: '通讯服务', value: 'COMMUNICATION_SERVICE' },
  { id: 10, name: '内置应用', value: 'BUILTIN_APPLICATION' },
]
