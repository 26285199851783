<template>
  <div class="user-login-privacy">
    <template v-if="checked">
      <span>登录视为您已同意</span>
      <span class="hl" @click="toPrivacyPage">&nbsp;Metacare用户协议与隐私政策</span>
    </template>
    <template v-else>
      <a-checkbox @change="(e) => $emit('on-change', e?.target?.checked || false)">
        <span>我已阅读并同意</span>
      </a-checkbox>
      <span @click="toPrivacyPage" style="margin-left: -10px">
        <span class="hl">&nbsp;Metacare用户协议与隐私政策</span>
      </span>
    </template>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  props: {
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toPrivacyPage() {
      window.open('http://www.metacare.net/policy.html', '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.user-login-privacy {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -90%); // -155%
  // display: flex;
  // justify-content: center;
  width: 420px;
  text-align: center;
  color: #999;

  .hl {
    color: #3570f5;
    cursor: pointer;
  }

  z-index: 1001;
}
</style>
