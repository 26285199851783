<template>
  <div class="footer">
    <div class="div-center v-page-center">
      <div ref="phoneEmail" class="phone-email flex-between-center">
        <div class="pe-item flex-side-center" v-for="link in footer.links" :key="link.id">
          <img class="icon" :src="link.icon" :alt="link.label" />
          <div class="flex-column flex-main-between">
            <div>{{ link.value }}</div>
            <div class="label">{{ link.label }}</div>
          </div>
        </div>
      </div>
      <div class="service-example flex-center">
        <div style="margin-right: 24px; font-size: 16px; font-weight: 500">{{ footer.serviceCases[0].label }}</div>
        <div class="flex-side-center">
          <span v-for="(val, idx) in footer.serviceCases[0].value" :key="idx" style="margin-right: 24px">
            {{ val }}
          </span>
        </div>
      </div>
      <div class="copyright text-center">
        <span>{{ footer.copyright.company }}</span>
        <a class="record" :href="footer.copyright.record.link" target="_blank">
          &nbsp;{{ footer.copyright.record.name }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { footer } from '@/config'

export default {
  name: 'Footer',
  data() {
    return {
      footer,
    }
  },
}
</script>

<style scoped lang="scss">
.footer {
  background: url('~@/assets/image/footer-bg.png') no-repeat center / 100% 100%;

  .phone-email {
    // margin: 0 157px;
    padding: 18px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .pe-item {
      @include fc(16px, 500, #fff);

      &:first-child {
        padding-left: 157px;
      }

      &:last-child {
        padding-right: 143px;
      }

      .icon {
        margin-right: 10px;
        width: 60px;
        height: 60px;
      }

      .label {
        @include fc(14px, 400, #fff);
      }
    }
  }

  .service-example {
    // margin: 0 157px;
    padding: 18px 0;
    @include fc(14px, 400, rgba(255, 255, 255, 0.8));
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);

    .se-item {
      .title {
        margin-bottom: 14px;
        font-size: 16px;
        font-weight: 500;
      }

      span {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .copyright {
    padding: 18px 0;
    @include fc(12px, 400, rgba(255, 255, 255, 0.5));

    .record {
      // margin-left: 14px;
      color: rgba(255, 255, 255, 0.5);

      &:hover {
        color: #066dff;
      }
    }
  }
}
</style>
