import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '首页',
    },
    component: Home,
  },
  {
    path: '/solution',
    name: 'Solution',
    meta: {
      title: '解决方案',
    },
    component: () => import(/* webpackChunkName: "solution" */ '@/views/Solution'),
  },
  {
    path: '/app-center',
    name: 'AppCenter',
    meta: {
      title: '应用中心',
    },
    component: () => import(/* webpackChunkName: "app-center" */ '@/views/AppCenter'),
  },
  {
    path: '/open-platform',
    name: 'OpenPlatform',
    meta: {
      title: '开放平台',
    },
    component: () => import(/* webpackChunkName: "open-platform" */ '@/views/OpenPlatform'),
  },
  {
    path: '*',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
