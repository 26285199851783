<template>
  <div class="card-title">
    <div class="title-en relative">
      <div class="title absolute">{{ title }}</div>
      <div>{{ letter }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
    letter: {
      type: String,
      required: true,
    },
  },
}
</script>

<style scoped lang="scss">
.card-title {
  .title-en {
    line-height: 44px;
    letter-spacing: 2px;
    @include fc(36px, 400, rgba(98, 105, 115, 0.1));

    .title {
      left: 50%;
      top: -12px;
      transform: translateX(-50%);
      @include fc(36px, 500, #222);
    }
  }
}
</style>
