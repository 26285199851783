<template>
  <div class="enterprise-step3">
    <img src="@/assets/image/icon_step3.png" alt="" />
    <div class="text">恭喜您，试用申请提交成功！</div>
    <div class="tips">审核通过后，客服将尽快和您联系，请您耐心等待...</div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.enterprise-step3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;

  img {
    margin-top: 20px;
    width: 160px;
    height: 160px;
  }

  .text {
    margin: 40px 0 20px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.8);
  }

  .tips {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
