<template>
  <div>
    <div v-if="placeholder" class="placeholder"></div>
    <div
      id="header"
      class="header flex-center fixed"
      :style="{
        background: placeholder ? 'rgba(255, 255, 255, 1)' : 'transparent',
      }">
      <div class="v-page-center flex-between-center">
        <div class="flex-between-center">
          <div class="logo">
            <img src="@/assets/image/header-logo.png" alt="logo" />
          </div>
          <div class="tabs">
            <a-tabs v-model="currTab" :animated="false" :tabBarStyle="tabBarStyle" @change="changeTabs">
              <a-tab-pane v-for="tab in tabs" :key="tab.key" :tab="tab.name"></a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="login flex-between-center">
          <div class="ai" @click="loginAI">{{ headerBtns.ai }}</div>
          <div class="saas" @click="loginSaas">{{ headerBtns.saas }}</div>
          <div class="trial" @click="useNow">{{ headerBtns.reg }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { headerTabs, headerBtns } from '@/config'
import EventBus from '@/utils/eventBus'

export default {
  name: 'Header',
  data() {
    return {
      currTab: '',
      tabBarStyle: {
        color: '#333',
        fontWeight: 500,
      },
      tabs: headerTabs,
      headerBtns,
    }
  },
  props: {
    placeholder: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    $route({ params }) {
      this.selectedAppCenterTab(params)
    },
  },
  created() {
    // 解决刷新页面时当前选中tab不是刷新前tab
    this.currTab = window.sessionStorage.getItem('CURR_TAB') || this.tabs[0].key
  },
  methods: {
    /**
     * 首页跳转到应用中心页面高亮选中应用中心Tab
     */
    selectedAppCenterTab(params) {
      const targetKey = 'AppCenter'
      if (params?.tab === targetKey) {
        this.currTab = targetKey
        window.sessionStorage.setItem('CURR_TAB', targetKey)
      }
    },
    changeTabs(name) {
      window.sessionStorage.setItem('CURR_TAB', name)
      this.$router.push({ name })
    },
    loginAI() {
      window.open(process.env.VUE_APP_AI_LOGIN_PATH, '_blank')
    },
    loginSaas() {
      window.open(process.env.VUE_APP_SAAS_LOGIN_PATH, '_blank')
    },
    useNow() {
      EventBus.$emit('ON_REGISTER_EVENT')
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .ant-tabs-ink-bar {
  height: 4px !important;
  background-color: $primary-color !important;
}

::v-deep .ant-tabs-nav .ant-tabs-tab-active {
  color: $primary-color !important;
}

::v-deep .ant-tabs-bar {
  border: none !important;
  margin: 0 !important;
}

::v-deep .ant-tabs-nav .ant-tabs-tab {
  margin: 0 !important;
  padding: 16px !important;
  font-size: $header-size !important;
}

.placeholder {
  height: $header-height;
}

.header {
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  width: 100vw;
  height: $header-height;
  z-index: 999;

  .logo img {
    margin-right: 100px;
    width: 104px;
    height: 42px;
  }

  .login {
    @include fc($header-size, 500, $secondary-color);

    .ai,
    .saas,
    .trial {
      margin-left: 26px;
      cursor: pointer;
    }

    .trial {
      margin-left: 26px;
      width: 104px;
      line-height: 44px;
      text-align: center;
      font-size: 16px;
      color: #fff;
      background: $primary-color;
      border-radius: 22px;
    }
  }
}
</style>
