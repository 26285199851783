export default {
  data() {
    return {
      alpha: 1,
      scrollTop: 0,
      headerElm: null,
      // appCenterNavElm: null,
      // appCenterCardsElm: null,
    }
  },
  deactivated() {
    // 记得在组件销毁前移除事件监听器，避免内存泄漏
    this.headerElm = null
    // this.appCenterNavElm = null
    // this.appCenterCardsElm = null
    window.removeEventListener('scroll', this.handleScroll)
  },
  activated() {
    this.headerElm = document.querySelector('#header')
    // this.appCenterNavElm = document.querySelector('.app-center .nav-list')
    // this.appCenterCardsElm = document.querySelector('.app-center .cards')
    window.addEventListener('scroll', this.handleScroll, { passive: true })
  },
  methods: {
    handleScroll() {
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop // 获取当前滚动位置
      this.headerElm.style.backgroundColor = `rgba(255, 255, 255, ${(this.scrollTop * 2) / 100})`
      /*
       * 应用中心侧边栏固定
       */
      // if (this.scrollTop > 150) {
      //   this.appCenterNavElm.classList.add('fixed-left-nav')
      //   this.appCenterCardsElm.classList.add('cards-left-gap')
      // } else {
      //   this.appCenterNavElm.classList.remove('fixed-left-nav')
      //   this.appCenterCardsElm.classList.remove('cards-left-gap')
      // }
    },
  },
}
