<template>
  <div v-if="visible" class="go-top" style="display: block; opacity: 0.801472" @click="scrollToTopApp">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-arrow-up">
      <line x1="12" y1="19" x2="12" y2="5"></line>
      <polyline points="5 12 12 5 19 12"></polyline>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BackToTop',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    scrollToTopApp() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    },
  },
}
</script>

<style scoped lang="scss">
.go-top {
  position: fixed;
  cursor: pointer;
  bottom: 30px;
  right: 0;
  color: #ffffff;
  background-color: $primary-color;
  z-index: 1;
  display: none;
  width: 45px;
  text-align: center;
  height: 45px;
  border-radius: 10px 0 0 10px;
  line-height: 60px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
</style>
