<template>
  <a-card class="forget-content">
    <FormClose @on-close="() => $emit('on-close')" />
    <div class="reg-page">
      <div class="title">欢迎注册Metacare</div>
      <!-- <div class="steps-header">
        <a-steps :current="current">
          <a-step v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps>
      </div> -->
      <div class="steps-content">
        <div>
          <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
            <a-form-model-item prop="mobile">
              <a-input placeholder="请输入手机号" v-model="form.mobile" allowClear>
                <a-icon slot="prefix" type="mobile" :style="{ color: 'rgba(0,0,0,.25)' }" />
              </a-input>
            </a-form-model-item>
            <a-form-model-item prop="code">
              <a-input placeholder="请输入验证码" v-model="form.code" allowClear>
                <a-icon slot="prefix" type="mail" :style="{ color: 'rgba(0,0,0,.25)' }" />
                <!-- eslint-disable vue/no-v-text-v-html-on-component -->
                <a-button
                  slot="suffix"
                  class="getCaptcha"
                  tabindex="-1"
                  :disabled="state.smsSendBtn"
                  @click.stop.prevent="getCaptcha"
                  v-text="(!state.smsSendBtn && '获取验证码') || state.time + ' s'"></a-button>
              </a-input>
              <!-- <a-button style="color: #1890ff">发送验证码</a-button> -->
            </a-form-model-item>
            <a-form-model-item prop="passwd">
              <a-input-password v-model="form.passwd" placeholder="请输入密码">
                <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }" />
              </a-input-password>
            </a-form-model-item>
            <a-form-model-item>
              <div style="position: relative">
                <slot name="privacy"></slot>
              </div>
              <div style="display: flex; justify-content: center; margin: 168px 0 0 0">
                <a-button
                  class="login-button"
                  size="large"
                  type="primary"
                  @click="onSubmit"
                  :loading="state.loginBtn"
                  :disabled="state.loginBtn">
                  下一步
                </a-button>
              </div>
              <!-- <a-button @click="toLogin" style="margin-right: 15px">返回登录</a-button> -->
            </a-form-model-item>
          </a-form-model>
        </div>
        <!-- <div v-if="current === 1" style="text-align: center">
          <div class="result-icon">
            <a-result status="success"> </a-result>
          </div>
          <h2>注册成功 !</h2>
          <a-button type="primary" @click="toLogin">去登录</a-button>
        </div> -->
        <div class="user-login-other">
          <span>已有账号?&nbsp;</span>
          <a style="color: #3570f5" @click="toLogin">立即登录</a>
        </div>
      </div>
    </div>
  </a-card>
</template>

<script>
import { validatePhone } from '@/utils/validate'
import { getSmsCaptcha, register } from '@/api/login'
import FormClose from '@/components/Register/Close.vue'

export default {
  name: 'ForgetPwd',
  components: {
    FormClose,
  },
  data() {
    return {
      loading: false,
      labelCol: { span: 6 },
      wrapperCol: { span: 14 },
      state: {
        time: 60,
        loginBtn: false,
        // login type: 0 email, 1 username, 2 telephone
        loginType: 0,
        smsSendBtn: false,
      },
      form: {
        mobile: '',
        code: '',
        passwd: '',
      },
      interval: null,
      rules: {
        mobile: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        passwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码至少为6个字符', trigger: 'blur' },
        ],
      },
      // current: 0,
      // steps: [
      //   {
      //     title: '账号注册',
      //   },
      //   {
      //     title: '注册成功',
      //   },
      // ],
    }
  },
  props: {
    accept: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getCaptcha() {
      const { state } = this
      const that = this
      that.$refs.ruleForm.validateField(['mobile'], (err) => {
        if (!err) {
          state.smsSendBtn = true

          this.interval = window.setInterval(() => {
            if (state.time-- <= 0) {
              state.time = 60
              state.smsSendBtn = false
              window.clearInterval(this.interval)
            }
          }, 1000)

          const params = {
            phone: that.form.mobile,
          }

          that.$message.loading('验证码发送中..', 0)
          getSmsCaptcha(params)
            .then((res) => {
              this.$message.destroy()
              if (res?.code === 0) {
                that.$message.success('验证码获取成功，请注意查收')
              } else {
                that.$message.warn(res.message)
              }
            })
            .catch((err) => {
              this.$message.destroy()
              this.interval && window.clearInterval(this.interval)
              state.time = 60
              state.smsSendBtn = false
              that.requestFailed(err)
            })
        }
      })
    },

    onSubmit() {
      const that = this
      const { form, state } = this
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true
          const params = {
            phone: form.mobile,
            validateCode: form.code,
            password: form.passwd,
          }
          if (!this.accept) {
            return this.$message.info('注册前请先阅读并同意Metacare用户协议与隐私政策')
          }
          console.log('注册表单参数', JSON.stringify(params, null, 2))
          register(params)
            .then((res) => {
              if (res?.code === 0) {
                that.loading = false
                // 将注册信息缓存到本地, 用于填写企业认证时回显
                window.sessionStorage.setItem('MC_REGISTER_INFO', JSON.stringify(params))
                that.$emit('on-submit')
              } else {
                this.interval && window.clearInterval(this.interval)
                state.time = 60
                state.smsSendBtn = false
                this.loading = false
                that.$message.warn(res.message)
              }
            })
            .catch((err) => {
              that.requestFailed(err)
            })
        }
      })
    },
    requestFailed(err) {
      this.loading = false
      this.isLoginError = true
      this.$message.warn(err?.message || '请求出现错误，请稍后再试')
    },
    toLogin() {
      // this.current = 0
      // this.$router.push({ path: '/' })
      this.$emit('on-login')
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep input {
  height: 50px;
  border-radius: 4px;
}

::v-deep {
  .ant-col {
    width: 100%;
  }
}

::v-deep .ant-form {
  padding: 0 24px;
}

::v-deep .ant-btn[disabled] {
  background: #fff;
}

.forget-content {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  width: 519px;
  height: 630px;
}

.getCaptcha {
  display: block;
  font-size: 14px;
  color: #3570f5;
  border: none;
}

button.login-button {
  padding: 0 15px;
  font-size: 16px;
  height: 50px;
  width: 417px;
  background: #3570f5;
  border-radius: 6px;
}

.title {
  margin: 20px 0 41px 0;
  padding-left: 22px;
  height: 36px;
  line-height: 36px;
  font-size: 26px;
  font-weight: 700;
  color: #000;
}

.user-login-other {
  display: flex;
  justify-content: center;
  // text-align: left;
  line-height: 22px;

  .item-icon {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.2);
    margin-left: 16px;
    vertical-align: middle;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #1890ff;
    }
  }

  .register {
    float: right;
  }
}
</style>
