<template>
  <div id="app">
    <Header :placeholder="appPlaceholder" />
    <div class="div-center" :style="appContainerStyle">
      <KeepAlive>
        <RouterView />
      </KeepAlive>
    </div>
    <Footer />
    <Register ref="registerRef" />
    <BackToTop :visible="appTopVisible" />
  </div>
</template>

<script>
import EventBus from '@/utils/eventBus'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import BackToTop from '@/components/BackToTop.vue'
import Register from '@/components/Register/index.vue'
import global from '@/mixins/global'

export default {
  name: 'App',
  mixins: [global],
  components: {
    Header,
    Footer,
    Register,
    BackToTop,
  },
  mounted() {
    EventBus.$on('ON_REGISTER_EVENT', () => {
      this.$refs.registerRef?.showModal()
    })
  },
  destroyed() {
    EventBus.$off('ON_REGISTER_EVENT')
  },
}
</script>

<style lang="scss"></style>
