import EventBus from '@/utils/eventBus'

export default {
  data() {
    return {
      appScrollTop: 0,
      appPlaceholder: false,
      appTopVisible: false,
      // 页面顶部不需要通栏的白名单(值是组件name)
      appPageWhiteList: ['Solution'],
    }
  },
  computed: {
    appContainerStyle() {
      return {
        minHeight: `calc(100vh - ${this.appPlaceholder ? '80px' : '0px'} - 212px)`,
      }
    },
  },
  watch: {
    $route: {
      handler({ name, meta: { title }, hash }) {
        // saas跳转过来注册
        if (hash === '#register') {
          this.$nextTick(() => {
            EventBus.$emit('ON_REGISTER_EVENT')
          })
        }
        document.title = `${title} - 哈哈智能 Health&Happiness`
        this.appPlaceholder = this.appPageWhiteList.includes(name)
        document.body.scrollTop = document.documentElement.scrollTop = 0
      },
      immediate: true,
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScrollApp)
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScrollApp)
  },
  methods: {
    handleScrollApp() {
      this.appScrollTop = document.documentElement.scrollTop || document.body.scrollTop
      this.appTopVisible = this.appScrollTop >= 400
    },
  },
}
