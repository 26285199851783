<template>
  <div v-if="maskVisible" class="website-modal">
    <div class="mask">
      <div class="form-section">
        <!-- 注册 -->
        <RegisterForm
          v-if="showRegisterForm"
          :accept="acceptPrivacy"
          @on-submit="handleRegisterSubmit"
          @on-login="handleLogin"
          @on-close="handleFormClose">
          <template #privacy>
            <PrivacyPolicy @on-change="handleAgreement"></PrivacyPolicy>
          </template>
        </RegisterForm>
        <!-- 企业认证 -->
        <EnterpriseCertification v-else @on-close="handleFormClose" />
      </div>
    </div>
  </div>
</template>

<script>
import RegisterForm from '@/components/Register/Form.vue'
import PrivacyPolicy from '@/components/Register/PrivacyPolicy.vue'
import EnterpriseCertification from '@/components/Enterprise/Certification.vue'

export default {
  name: 'Register',
  components: {
    RegisterForm,
    PrivacyPolicy,
    EnterpriseCertification,
  },
  data() {
    return {
      showRegisterForm: false,
      acceptPrivacy: false,
      maskVisible: false,
    }
  },
  methods: {
    showModal() {
      this.maskVisible = true
      this.showRegisterForm = true
    },
    handleRegisterSubmit() {
      this.showRegisterForm = false
    },
    handleFormClose() {
      this.maskVisible = false
      this.acceptPrivacy = false
      this.showRegisterForm = false
      this.$router.replace('/')
    },
    handleAgreement(v) {
      this.acceptPrivacy = v
    },
    handleLogin() {
      window.open(process.env.VUE_APP_SAAS_LOGIN_PATH, '_blank')
    },
  },
}
</script>

<style scoped lang="scss">
.website-modal {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1000;
  overflow: hidden;
  backdrop-filter: blur(15px);

  .mask {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(255, 255, 255, 0.1);
  }
}
</style>
