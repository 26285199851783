<template>
  <div class="enterprise-certification">
    <FormClose @on-close="() => $emit('on-close')" />
    <div class="ec-content">
      <div v-if="current === 0" class="text-center">
        <div class="ec-title">填写以下信息，即可体验Metacare智慧养老服务</div>
        <div style="margin-top: 10px; font-size: 14px; font-weight: 400; color: rgba(0, 0, 0, 0.45)">
          产品处于内测阶段，提交申请后请您耐心等待，后续我们的客服会与您取得联系并为您开通试用权限。
        </div>
      </div>
      <div class="ec-steps">
        <!-- <a-steps :current="current">
          <a-step v-for="item in steps" :key="item.title" :title="item.title" />
        </a-steps> -->
        <div class="steps-content">
          <EnterPriseStep1 v-if="current === 0"></EnterPriseStep1>
          <!-- <EnterPriseStep2 v-else-if="current === 1"></EnterPriseStep2> -->
          <EnterPriseStep3 v-else-if="current === 1"></EnterPriseStep3>
        </div>
        <div class="steps-opra">
          <div class="steps-next" @click="next">{{ nextText }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/utils/eventBus'
import FormClose from '@/components/Register/Close.vue'
import EnterPriseStep1 from './Step1.vue'
// import EnterPriseStep2 from './Step2'
import EnterPriseStep3 from './Step3.vue'
import { createTrialApplication } from '@/api/login'

export default {
  name: 'EnterpriseCertification',
  components: {
    FormClose,
    EnterPriseStep1,
    // EnterPriseStep2,
    EnterPriseStep3,
  },
  data() {
    return {
      current: 0,
      steps: [
        {
          title: '完善企业信息',
          content: 'First-content',
        },
        // {
        //   title: '选择感兴趣的设备',
        //   content: 'Second-content',
        // },
        {
          title: '试用申请成功',
          content: 'Last-content',
        },
      ],
    }
  },
  computed: {
    nextText() {
      return this.current === 0 ? '提交申请' : '我知道了'
    },
  },
  mounted() {},
  methods: {
    checkFormPass() {
      EventBus.$once('ON_CERTIFICATION_RESULT', async ({ valid, params }) => {
        if (valid) {
          console.log('params', JSON.stringify(params, null, 2))
          try {
            const { code, data } = await createTrialApplication(params)
            if (code === 0 && data) {
              this.current++
            }
          } catch (err) {
            console.error(err)
          }
        }
      })
    },
    next() {
      // return this.current++
      if (this.current === 0) {
        this.checkFormPass()
        EventBus.$emit('ON_CERTIFICATION_VALIDATE')
      } else {
        this.$emit('on-close')
        window.sessionStorage.setItem('MC_REGISTER_INFO', '{}')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .ant-steps {
  margin: 0 auto;
  width: 50%;
}

.enterprise-certification {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 60px 20px 0;
  width: 980px;
  height: 660px;
  // width: 1200px;
  // height: 744px;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 12px 12px 12px 12px;

  .ec-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .ec-title {
      font-size: 26px;
      font-weight: 700;
      color: #000;
    }

    .ec-steps {
      // margin-top: 50px;
      width: 100%;

      // .steps-content {
      //   margin-top: 20px;
      // }

      .steps-opra {
        position: absolute;
        left: 50%;
        bottom: 60px;
        transform: translateX(-50%);

        .steps-next {
          width: 418px;
          line-height: 50px;
          background: #3570f5;
          border-radius: 6px;
          text-align: center;
          font-size: 16px;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
