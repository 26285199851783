<template>
  <div class="enterprise-step1">
    <a-form-model
      ref="ruleForm"
      layout="horizontal"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol">
      <a-form-model-item ref="name" label="企业名称" prop="companyName">
        <a-input v-model="form.companyName" placeholder="请输入企业名称" />
      </a-form-model-item>
      <a-row>
        <a-col :span="12">
          <a-form-model-item
            label="联系人"
            :label-col="{ span: 8 }"
            :wrapperCol="{ span: 14 }"
            prop="responsiblePerson">
            <a-input v-model="form.responsiblePerson" placeholder="请输入联系人" />
          </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item :label-col="{ span: 6 }" :wrapperCol="{ span: 14 }" label="联系电话" prop="mobile">
            <a-input v-model="form.mobile" disabled />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item label="联系邮箱" prop="email">
        <a-input v-model="form.email" placeholder="请输入联系邮箱" />
      </a-form-model-item>
      <a-form-model-item label="您的需求" prop="description">
        <a-textarea
          v-model="form.description"
          :autosize="{ minRows: 4, maxRows: 4 }"
          placeholder="请填写您的行业，需要在什么场景下使用什么功能，以便于我们的客服进行回访联系。" />
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
//
<script>
import EventBus from '@/utils/eventBus'

export default {
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 18 },
      form: {
        companyName: '',
        responsiblePerson: '',
        mobile: '',
        email: '',
        description: '',
      },
      rules: {
        companyName: [{ required: true, message: '请输入企业名称', trigger: 'blur' }],
        description: [{ required: true, message: '请输入您的需求', trigger: 'blur' }],
        responsiblePerson: [{ required: true, message: '请输入联系人', trigger: 'blur' }],
        mobile: [
          { required: true, message: '请输入联系电话', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的联系电话', trigger: 'blur' },
        ],
        email: [
          { required: true, message: '请输入联系邮箱', trigger: 'blur' },
          {
            pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
            message: '请输入正确的联系邮箱',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  mounted() {
    // 从本地缓存取出回显联系电话
    const { phone } = JSON.parse(window.sessionStorage.getItem('MC_REGISTER_INFO') || '{}')
    this.form.mobile = phone || ''
    EventBus.$on('ON_CERTIFICATION_VALIDATE', this.onSubmit)
  },
  methods: {
    onSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          const params = this.form
          EventBus.$emit('ON_CERTIFICATION_RESULT', { valid, params })
        } else {
          EventBus.$emit('ON_CERTIFICATION_RESULT', { valid: false })
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep input {
  height: 50px;
  border-radius: 4px;
}

::v-deep .ant-form {
  padding: 0 24px;
}

::v-deep .ant-form-item {
  display: flex;
}

::v-deep .ant-form-item-label {
  font-weight: 700;
  line-height: 50px;
}

.enterprise-step1 {
  padding-top: 50px;
}
</style>
