<template>
  <div class="home">
    <!-- banner -->
    <div class="banner">
      <div class="v-page-center div-center h-pct-100 flex-between-center">
        <div class="intro">
          <div class="title">{{ banner.title }}</div>
          <div class="sub-title">{{ banner.subTitle }}</div>
          <div class="desc">{{ banner.desc }}</div>
          <div class="btn text-center pointer" @click="useNow">{{ banner.btnText }}</div>
        </div>
        <div class="icon">
          <img :src="banner.icon" alt="banner_icon" />
        </div>
      </div>
    </div>

    <!-- 产品介绍 -->
    <div class="introduce relative">
      <div class="v-page-center div-center">
        <div class="text-center base-card-title">
          <CardTitle :title="cardTitles.intro.title" :letter="cardTitles.intro.letter" />
        </div>
        <div class="flex-between-center">
          <div class="block text-center" v-for="intro in introduces" :key="intro.id">
            <div class="title">{{ intro.title }}</div>
            <div class="desc">{{ intro.desc }}</div>
            <img class="icon" :src="intro.icon" :alt="intro.title" />
          </div>
        </div>
      </div>
    </div>

    <!-- 自定义工作流 -->
    <div class="workflow">
      <div class="v-page-center div-center">
        <div class="text-center base-card-title">
          <CardTitle :title="cardTitles.workflow.title" :letter="cardTitles.workflow.letter" />
        </div>
        <div class="flex-between-center">
          <div class="workflow-item" v-for="workflow in homeWorkflows" :key="workflow.id">
            <div class="workflow-item_info">
              <div class="workflow-item_title flex-center">
                <img :src="workflow.icon" :alt="workflow.title" />
                <div>{{ workflow.title }}</div>
              </div>
              <div class="flex-between-center">
                <span class="workflow-item_desc" v-for="(text, idx) in workflow.desc" :key="idx">{{ text }}</span>
              </div>
            </div>
            <img :src="workflow.thumbnail" :alt="workflow.title" />
          </div>
        </div>
      </div>
    </div>

    <!-- 自定义AI助理 -->
    <div class="ai-agent">
      <div class="v-page-center div-center">
        <div class="text-center base-card-title">
          <CardTitle :title="cardTitles.ai.title" :letter="cardTitles.ai.letter" />
        </div>
        <div class="flex-between-center">
          <div class="ai-agent-features flex-column flex-main-between">
            <div class="ai-agent-features_item" v-for="item in aiAgents.features" :key="item.id">
              <img :src="item.icon" alt="" />
              <div class="ai-agent-features_item_info">
                <div v-for="(text, idx) in item.label" :key="idx">{{ text }}</div>
              </div>
            </div>
          </div>
          <div class="ai-agent-agents flex-column">
            <img :src="url" v-for="url in aiAgents.agents" :key="url" alt="" />
          </div>
          <div class="ai-agent-thumbnail">
            <img :src="aiAgents.thumbnail" alt="" />
          </div>
        </div>
      </div>
    </div>

    <!-- 居家人工智能设备 -->
    <div class="intelligent-hardware">
      <div class="v-page-center div-center">
        <div class="text-center base-card-title">
          <CardTitle :title="cardTitles.intelligence.title" :letter="cardTitles.intelligence.letter" />
        </div>
        <div class="flex-between-center">
          <div class="intelligent-hardware-item" v-for="(item, index) in intelligences" :key="item.id">
            <img :src="item.thumbnail" :alt="item.title" />
            <div
              class="intelligent-hardware-item_card"
              :class="[index === 0 ? ['text-left', 'style-left'] : ['text-right', 'style-right']]">
              <div class="intelligent-hardware-item_card_title">{{ item.title }}</div>
              <div class="none">
                <div class="intelligent-hardware-item_card_label" v-for="(text, idx) in item.label" :key="idx">
                  {{ text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 100+智能硬件设备 -->
    <div class="more-devices">
      <div class="v-page-center div-center">
        <div class="text-center base-card-title" style="padding-bottom: 0">
          <CardTitle :title="cardTitles.moreDevices.title" :letter="cardTitles.moreDevices.letter" />
        </div>
        <div class="more-content flex-column flex-between-center">
          <div class="supplement">{{ cardTitles.moreDevices.subTitle }}</div>
          <div class="scroll-block scroll-x">
            <div class="scroll-container flex-side-center">
              <div
                class="scroll-item flex-column flex-between-center flex-auto"
                v-for="app in setAppSeamlessScroll"
                :key="app.id">
                <img class="icon" :src="app.icon" :alt="app.label" />
                <span>{{ app.label }}</span>
                <span style="font-size: 12px">品牌:&nbsp;&nbsp;{{ app.brand }}</span>
                <img v-if="app.hot" class="tag" src="@/assets/image/home/app-tag-hot.png" alt="" />
              </div>
            </div>
          </div>
          <div class="go-app-center flex-between-center pointer" @click="goAppCenter">
            <span>前往应用中心</span>
            <img class="flex-auto" src="@/assets/image/home/icon_go.png" alt="前往应用中心" />
          </div>
        </div>
      </div>
    </div>

    <!-- 引导体验 -->
    <div class="use-us">
      <div class="v-page-center div-center h-pct-100 flex-end-center">
        <div class="btn text-center pointer" @click="useNow">{{ banner.btnText }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import EventBus from '@/utils/eventBus'
import scroll from '@/mixins/scroll'
import CardTitle from '@/components/CardTitle'
import {
  homeIntroduces,
  homeBanner,
  homeCardTitles,
  homeWorkflows,
  aiAgents,
  moreDevices,
  intelligences,
} from '@/config'

export default {
  name: 'Home',
  mixins: [scroll],
  components: {
    CardTitle,
  },
  data() {
    return {
      banner: homeBanner,
      cardTitles: homeCardTitles,
      introduces: homeIntroduces,
      scrollApps: moreDevices,
      homeWorkflows,
      aiAgents,
      intelligences,
    }
  },
  computed: {
    /**
     * 整两份一样的数据,实现无缝滚动的效果!
     */
    setAppSeamlessScroll() {
      const apps = []
      // 使用深拷贝解决引用导致索引重复的问题
      const apps1 = JSON.parse(JSON.stringify(this.scrollApps))
      const apps2 = JSON.parse(JSON.stringify(this.scrollApps))
      // 整个屏幕滚动添加app3, 版心宽度滚动注释app3
      const apps3 = JSON.parse(JSON.stringify(this.scrollApps))
      ;[...apps1, ...apps2, ...apps3].forEach((app) => apps.push(app))
      apps.forEach((app, idx) => (app.id = idx))
      return apps
    },
  },
  methods: {
    goAppCenter() {
      const name = 'AppCenter'
      this.$router.push({ name, params: { tab: name } })
    },
    useNow() {
      EventBus.$emit('ON_REGISTER_EVENT')
    },
  },
}
</script>

<style scoped lang="scss">
$home-primary-bg: #f2f5f8;

.banner {
  padding-top: 80px;
  height: 640px;
  background: url('~@/assets/image/home/banner_bg.png') no-repeat center / 100% 100%;

  .intro {
    // max-width: 500px;
    margin-right: 60px;

    .title {
      margin-bottom: 8px;
      line-height: 64px;
      @include fc(56px, 700, #061d44);
    }

    .sub-title {
      line-height: 40px;
      margin-bottom: 28px;
      @include fc(32px, 500, #061d44);
    }

    .desc {
      margin-bottom: 44px;
      line-height: 30px;
      @include fc(18px, 400, #626973);
    }

    .btn {
      width: 124px;
      line-height: 44px;
      background: #066dff;
      border-radius: 22px;
      @include fc(16px, 500, #fff);
    }
  }

  .icon {
    padding-right: 59px;

    img {
      width: 534px;
      height: 442px;
    }
  }
}

.introduce {
  height: 877px;

  .block {
    .title {
      margin-bottom: 16px;
      line-height: 32px;
      @include fc(24px, 500, #222);
    }

    .desc {
      margin-bottom: 24px;
      line-height: 24px;
      @include fc(16px, 400, #626973);
    }

    .icon {
      width: 522px;
      height: 505px;
    }
  }
}

.workflow {
  height: 646px;
  background-color: $home-primary-bg;

  .workflow-item {
    width: 384px;

    &_info {
      padding: 0 74px;
    }

    img {
      width: 384px;
      height: 286px;
    }

    &_title {
      margin-bottom: 20px;
      line-height: 32px;
      @include fc(24px, 500, #222);

      img {
        margin-right: 6px;
        width: 36px;
        height: 36px;
      }
    }

    &_desc {
      margin-bottom: 20px;
      line-height: 24px;
      @include fc($color: #626973);
    }
  }
}

.ai-agent {
  height: 676px;

  &-features {
    width: 265px;

    &_item {
      img {
        width: 42px;
        height: 42px;
      }

      &_info {
        margin-bottom: 24px;
        @include fc(22px, 400, #222);

        & > div {
          line-height: 30px;
        }
      }
    }
  }

  &-agents {
    img {
      width: 180px;
      height: 180px;
    }
  }

  &-thumbnail {
    img {
      width: 588px;
      height: 400px;
    }
  }
}

.intelligent-hardware {
  height: 826px;
  background-color: $home-primary-bg;

  &-item {
    img {
      width: 588px;
      height: 406px;
      vertical-align: bottom;
    }

    $item-gap: 40px;

    .style-left {
      padding-left: $item-gap;
      border-radius: 0 0 0 24px;
    }

    .style-right {
      padding-right: $item-gap;
      border-radius: 0 0 24px 0;
    }

    &_card {
      width: 588px;
      height: 144px;
      background: linear-gradient(180deg, #f2f5f8 0%, #ffffff 100%);

      &_title {
        padding: 24px 0 16px 0;
        line-height: 32px;
        @include fc(24px, 500, #222);
      }

      &_label {
        line-height: 24px;
        @include fc($color: #555);
      }
    }
  }
}

.more-devices {
  height: 554px;
  overflow-x: hidden;

  .more-content {
    height: 430px;
    padding-bottom: 60px;

    .supplement {
      @include fc($color: $primary-color);
    }

    .scroll-block {
      // 全屏滚动不指定width, 版心宽度滚动需指定下面width
      //width: $v-page-center;

      .scroll-container {
        $scroll-item-count: 17; // 滚动个数, 手动修改 (根据moreDevices计算个数)
        $scroll-item-gap: 24px;
        $scroll-item-width: 180px;

        animation: scroll 60s linear infinite;

        &:hover {
          animation-play-state: paused;
        }

        @keyframes scroll {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(-($scroll-item-width + $scroll-item-gap) * $scroll-item-count);
          }
        }

        .scroll-item {
          position: relative;
          width: $scroll-item-width;
          height: 186px;
          margin-right: $scroll-item-gap;
          @include fc(18px, 400, #4e5969);

          .tag {
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            height: 50px;
            z-index: 2;
          }

          .icon {
            width: 100%;
            height: 132px;
          }

          &:last-child {
            margin-right: 0;
          }

          &:last-child:not(.virtual) {
            margin-right: -100%;
          }
        }
      }
    }

    .go-app-center {
      padding: 0 18px;
      width: 167px;
      height: 44px;
      @include fc(16px, 500, #fff);
      background: $primary-color;
      border-radius: 22px;

      img {
        width: 31px;
        height: 31px;
      }
    }
  }
}

.use-us {
  height: 90px;
  background: url('@/assets/image/home/yindao.png') no-repeat center / 100% 100%;

  .btn {
    margin-right: 102px;
    width: 124px;
    line-height: 44px;
    background: #ffffff;
    border-radius: 22px;
    @include fc(16px, 500, $primary-color);
  }
}

.base-card-title {
  padding: 80px 0 60px 0;
}
</style>
