import axios from 'axios'

const request = axios.create({
  baseURL: '/api',
  timeout: 10000,
  headers: {
    'Content-Security-Policy': 'upgrade-insecure-requests',
  },
})

request.interceptors.request.use(
  (config) => {
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

request.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    return Promise.reject(error)
  },
)

/**
 * 批量生成API函数
 * @param method 请求方式: get / post, 默认 post
 * @returns {function(*): function(*=): Promise<axios.AxiosResponse<any>>}
 */
const batchGenFn = (method = 'post') => {
  /**
   * @param url api 接口地址
   * @param data 请求接口参数, 默认 {}
   */
  return (url) =>
    (data = {}) =>
      request({ url, method, data })
}

export { request as axios, batchGenFn }
